import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import type { Newsletter as NewsletterComponentData } from '../ComponentTypes';
import fetch from '../../../../js/api/fetch';
import type { OnFormFieldChange } from '../../../page-manager/types/formTypes';
import { useWithSiteUrl } from '../../WebshopContext/WebshopContext';
import styles from './Newsletter.module.scss';
import Icon from '../../../../stories/Atoms/Icon/Icon';
import NewsletterDialog from './NewsletterDialog';
import useTranslate from '../../Translation/hooks/UseTranslate';

interface UspsProps {
    component: NewsletterComponentData;
    isEditable: boolean;
}

interface ResponseData {
    message: string;
    success: boolean;
}

const Newsletter = React.forwardRef<HTMLDivElement, UspsProps>(({ component, isEditable }, ref) => {
    const {
        buttonText, description, successText, successTitle, title, usps,
    } = component;
    const uspItems = usps.filter((i) => !!i.text);
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const emailInputPlaceholderText = useTranslate()('chunk.footer', 'email');
    const withSiteUrl = useWithSiteUrl();

    useEffect(() => {
        setError('');
    }, [email]);

    const handleInputChange: OnFormFieldChange = (event) => {
        setEmail(event.target.value);
    };

    const closeModal = () => {
        setEmail('');
        setIsSuccess(false);
    };

    const updateStateByResponse = (success: boolean, errorMessage: string) => {
        setIsSuccess(success);
        setError(errorMessage);
    };

    if (!isEditable && !title) {
        return null;
    }

    const handleSubmit = (event: React.FormEvent<EventTarget>) => {
        event.preventDefault();
        fetch(withSiteUrl('/email/subscribe-to-b2c-newsletter'), {
            body: JSON.stringify({ email, method: 'homepage' }),
            method: 'POST',
        })
            .then((response) => response.json())
            .then((data: ResponseData) => {
                if (data.success) {
                    updateStateByResponse(true, '');
                } else {
                    updateStateByResponse(false, data.message);
                }
            }).catch(() => {
                updateStateByResponse(false, 'Please try again');
                throw new Error('Newsletter homepage subscription request failed');
            });
    };

    return (
        <div className="section section--xl">
            {isSuccess && <NewsletterDialog handleCloseDialog={closeModal} successText={successText} successTitle={successTitle} /> }
            <div className="wrapper wrapper--md" ref={ref}>
                <div className={styles.Newsletter}>
                    <div className={classNames('content content__text--light p-0', styles.Newsletter__content)}>
                        <span className="h3">{title}</span>
                        <p>{description}</p>
                    </div>
                    <div className={styles.Newsletter__usp}>
                        <ul className="lh-lg">
                            {uspItems.map((usp) => <li key={usp.id}> <Icon style='fa-thin' name='fa-badge-check' size='fa-xl' /><span>{usp.text}</span></li>)}
                        </ul>
                    </div>
                    <form className={classNames(styles.Newsletter__form)} onSubmit={handleSubmit}>
                        <div className="form-control">
                            <input placeholder={emailInputPlaceholderText || 'name@email.com'} type="email" value={email} onChange={handleInputChange} required
                                className={classNames('form-input', 'no-label', 'input-large', 'input-transparent', { error })} />
                            <button type="submit" className="button form-control-action button--happy-path" disabled={Boolean(error)}>
                                <span>{buttonText}</span>
                            </button>
                        </div>
                        {error
                            && <ul className="form-error">
                                <li className="error">
                                    <Icon name='fa-circle-exclamation' />{error}
                                </li>
                            </ul> }
                    </form>
                </div>
            </div>
        </div>
    );
});

export default Newsletter;
