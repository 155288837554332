import React from 'react';
import type { ReactElement } from 'react';
import type { Categories as CategoriesType } from '../ComponentTypes';
import ImageSlider from './ImageSlider';
import ButtonSlider from './ButtonSlider';
import Section from '../../Section/Section';

interface CategoriesProps {
    categories: CategoriesType['links'];
    draggableWrapper?: ReactElement;
    isDraggable?: boolean;
    itemWrapper?: ReactElement;
    withImages: boolean;
}

const Categories = React.forwardRef<HTMLDivElement, CategoriesProps>(({ categories, draggableWrapper, isDraggable, itemWrapper, withImages }, ref) => {
    const shownCategories = categories.filter((c) => itemWrapper || c.show);
    if (!shownCategories.length) {
        return null;
    }

    return (
        <Section noPadding hasBorderBottom ref={ref}>
            {withImages
                ? <ImageSlider categories={shownCategories} draggableWrapper={draggableWrapper} isDraggable={isDraggable} itemWrapper={itemWrapper} />
                : <ButtonSlider categories={shownCategories} draggableWrapper={draggableWrapper} isDraggable={isDraggable} itemWrapper={itemWrapper} />}
        </Section>
    );
});

export default Categories;
